<template>
  <v-app>
    <v-app-bar sticky color="primary" dark app>
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          contain
          gradient="to bottom left, rgba(100,115,201,.4), rgba(25,32,72,1)"
        ></v-img>
      </template>

      <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
      <v-avatar size="70" class="mt-11">
        <v-img src="@/assets/praxis-dier-logo.png"></v-img>
      </v-avatar>

      <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'ml-1' : 'ml-6'"
        >Dr. Annette Dier <span v-if="$vuetify.breakpoint.mdAndUp">| </span
        ><br v-else /><span class="font-weight-thin"
          >Fachärztin für Gynäkologie und Geburtshilfe</span
        ></v-toolbar-title
      >

      <v-spacer></v-spacer>

      <template v-slot:extension>
        <v-tabs
          align-with-title
          :show-arrows="$vuetify.breakpoint.smAndDown"
          class="ml-8"
        >
          <v-tab :to="{ path: '/' }">Home</v-tab>
          <v-tab :to="{ path: '/aktuelles' }">Aktuelles</v-tab>
          <v-tab :to="{ path: '/leistungen' }">Leistungen</v-tab>
          <v-tab :to="{ path: '/werdegang' }">Werdegang</v-tab>
          <v-tab :to="{ path: '/anfahrt' }">Öffnungszeiten &#38; Anfahrt</v-tab>
          <v-tab :to="{ path: '/corona' }">Corona</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main class="gyn-bg">
      <transition name="router-view" mode="out-in">
        <router-view class="transition"></router-view>
      </transition>
    </v-main>
    <v-footer padless color="secondary">
      <v-col class="text-center" cols="12">
        <v-btn text @click="impressum = !impressum">Impressum</v-btn> |
        <v-btn text @click="datenschutz = !datenschutz">Datenschutz</v-btn>
      </v-col>
    </v-footer>
    <v-bottom-sheet v-model="impressum">
      <v-sheet height="76vh">
        <v-btn
          :class="$vuetify.breakpoint.smAndDown? 'mt-2 ml-2':'mt-6 ml-16'"
          text
          color="red"
          @click="impressum = !impressum"
        >
          zurück
        </v-btn>
        <v-card flat :class="$vuetify.breakpoint.smAndDown? 'mb-4 px-2':'mb-10 px-16'">
          <v-card-title class="font-weight-bold mb-4"
            >Angaben gemäß § 5 TMG</v-card-title
          >
          <v-card-text style="overflow-y: scroll; height: 50vh"
            ><strong>Dr. med. Annette Dier</strong><br />
            Neue Bruchstraße 2a<br />
            76275 Ettlingen<br />
            Tel: (07243) 5247969 oder (07243) 9616 <br />E-mail:
            kontakt@frauenaerztin-dier.de<br />
            Hinweis: Über die o.g. E-mail Addresse erfolgt keine Beratung zu
            medizinischen Behandlungen. <br /><br /><strong
              >Aufsichtsbehörde</strong
            ><br />
            Zuständige Kassenärztliche Vereinigung:<br />
            Kassenärztliche Vereinigung Baden-Württemberg<br />
            Bezirksdirektion Karlsruhe, Regionalbüro Karlsruhe | Kesslerstr. 1 |
            76185 Karlsruhe<br />
            http://www.kvbawue.de<br /><br />
            <strong>Berufsbezeichnung und berufsrechtliche Regelungen</strong
            ><br />
            Berufsbezeichnung: Ärztin <br />Zuständige Kammer: Landesärztekammer
            Baden-Württemberg | Kesslerstr. 1 | 76185 Karlsruhe <br />Verliehen
            durch: Deutschland<br />
            Geltende berufsrechtliche Regelungen einsehbar unter:
            http://www.kvbawue.de <br /><br />
            Website Inhalte &copy; Dr. Annette Dier<br />
            Website Design &amp; technische Umsetzung &copy; Benjamin Dier
            <br /><br /><strong>Haftung für Inhalte </strong><br />Gemäß § 7
            Abs.1 TMG ist der Betreiber einer Website für eigene Inhalte nach
            den allgemeinen Gesetzen verantwortlich. <br />Gemäß §§ 8 bis 10 TMG
            besteht jedoch keine Verpflichtung, übermittelte oder gespeicherte
            fremde Informationen zu überwachen oder nach Umständen zu forschen,
            die auf eine rechtswidrige Tätigkeit hinweisen.
            <br />Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen wird der
            Betreiber der Webseite diese Inhalte umgehend entfernen.
            <br /><br /><strong>Haftung für Links </strong><br />Diese Webseite
            enthält Links zu externen Websites Dritter, auf deren Inhalte wir
            keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte
            auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten
            ist stets der jeweilige Anbieter oder Betreiber der Seiten
            verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
            Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
            Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
            <br />Eine permanente inhaltliche Kontrolle der verlinkten Seiten
            ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen. <br /><br /><strong
              >Urheberrecht</strong
            ><br />
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. <br />Soweit die Inhalte auf
            dieser Seite nicht vom Betreiber erstellt wurden, werden die
            Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
            als solche gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.</v-card-text
          >
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="datenschutz">
      <v-sheet height="76vh">
        <v-btn
          :class="$vuetify.breakpoint.smAndDown? 'mt-2 ml-2':'mt-6 ml-16'"
          text
          color="red"
          @click="datenschutz = !datenschutz"
        >
          zurück
        </v-btn>
        <v-card flat :class="$vuetify.breakpoint.smAndDown? 'mb-4 px-2':'mb-10 px-16'">
          <v-card-title class="font-weight-bold mb-4"
            >Datenschutzerklärung für Websitebetreiber nach
            Datenschutzgrundverordnung (DSGVO)</v-card-title
          >
          <v-card-text style="overflow-y: scroll; height: 50vh">
            <strong>I. Name und Anschrift des Verantwortlichen</strong><br />
            Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und
            anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie
            sonstiger datenschutzrechtlicher Bestimmungen ist:<br />
            Dr. med. Annette Dier <br />Neue Bruchstraße 2a 76275 Ettlingen
            <br />Tel: (07243) 9616 <br />E-mail: kontakt@frauenaerztin-dier.de
            <br />Hinweise: Über die o.g. E-mail Addresse erfolgt keine Beratung
            zu medizinischen Behandlungen.<br /><br /><strong>
              II. Allgemeines zur Datenverarbeitung</strong
            ><br />
            1. Umfang der Verarbeitung personenbezogener Daten<br />
            Wir erheben und verwenden personenbezogene Daten unserer Nutzer
            grundsätzlich nur, soweit dies zur Bereitstellung einer
            funktionsfähigen Website sowie unserer Inhalte und Leistungen
            erforderlich ist. Die Erhebung und Verwendung personenbezogener
            Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des
            Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine
            vorherige Einholung einer Einwilligung aus tatsächlichen Gründen
            nicht möglich ist und die Verarbeitung der Daten durch gesetzliche
            Vorschriften gestattet ist. <br /><br />2. Rechtsgrundlage für die
            Verarbeitung personenbezogener Daten <br />Soweit wir für
            Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der
            betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a
            EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage. Bei der
            Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines
            Vertrages, dessen Vertragspartei die betroffene Person ist,
            erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als
            Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
            Durchführung vorvertraglicher Maßnahmen erforderlich sind.
            <br />Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung
            einer rechtlichen Verpflichtung erforderlich ist, der unser
            Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
            Rechtsgrundlage.<br />
            Für den Fall, dass lebenswichtige Interessen der betroffenen Person
            oder einer anderen natürlichen Person eine Verarbeitung
            personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
            lit. d DSGVO als Rechtsgrundlage. <br />Ist die Verarbeitung zur
            Wahrung eines berechtigten Interesses unseres Unternehmens oder
            eines Dritten erforderlich und überwiegen die Interessen,
            Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte
            Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
            Rechtsgrundlage für die Verarbeitung. <br /><br />3. Datenlöschung
            und Speicherdauer <br />Die personenbezogenen Daten der betroffenen
            Person werden gelöscht oder gesperrt, sobald der Zweck der
            Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen,
            wenn dies durch den europäischen oder nationalen Gesetzgeber in
            unionsrechtlichen Verordnungen, Gesetzen oder sonstigen
            Vorschriften, denen der Verantwortliche unterliegt, vorgesehen
            wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn
            eine durch die genannten Normen vorgeschriebene Speicherfrist
            abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren
            Speicherung der Daten für einen Vertragsabschluss oder eine
            Vertragserfüllung besteht. <br /><br /><strong
              >III. Bereitstellung der Website und Erstellung von
              Logfiles</strong
            ><br />
            1. Beschreibung und Umfang der Datenverarbeitung<br />
            Bei jedem Aufruf unserer Internetseite erfasst unser System
            automatisiert Daten und Informationen vom Computersystem des
            aufrufenden Rechners. <br />Folgende Daten werden hierbei erhoben:
            <br />
            <ul>
              <li>
                Informationen über den Browsertyp und die verwendete
                Browserversion
              </li>
              <li>das Betriebssystem des Nutzers</li>
              <li>die IP-Adresse des Nutzers</li>
              <li>Datum und Uhrzeit der Serveranfrage</li>
              <li>
                Websites, von denen das System des Nutzers auf unsere
                Internetseite gelangt
              </li>
              <li>
                Websites, die vom System des Nutzers über unsere Website
                aufgerufen werden
              </li>
            </ul>
            Die Daten werden ebenfalls in den Logfiles unseres Systems
            gespeichert. Eine Speicherung dieser Daten zusammen mit anderen
            personenbezogenen Daten des Nutzers findet nicht statt.
            <br /><br />2. Rechtsgrundlage für die Datenverarbeitung
            <br />Rechtsgrundlage für die vorübergehende Speicherung der Daten
            und der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO. <br /><br />3.
            Zweck der Datenverarbeitung <br />Die vorübergehende Speicherung der
            IP-Adresse durch das System ist notwendig, um eine Auslieferung der
            Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die
            IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert
            bleiben. <br />Die Speicherung in Logfiles erfolgt, um die
            Funktionsfähigkeit der Website sicherzustellen. Zudem dienen uns die
            Daten zur Optimierung der Website und zur Sicherstellung der
            Sicherheit unserer informationstechnischen Systeme. Eine Auswertung
            der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht
            statt. <br />In diesen Zwecken liegt auch unser berechtigtes
            Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
            <br /><br />4. Dauer der Speicherung <br />Die Daten werden
            gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung
            nicht mehr erforderlich sind. Im Falle der Speicherung der Daten in
            Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine
            darüberhinausgehende Speicherung ist möglich, beispielsweise um
            forensische Maßnahmen im Falle eines Angriffs durchzuführen.
            <br /><br />5. Widerspruchs- und Beseitigungsmöglichkeit<br />
            Die Erfassung der Daten zur Bereitstellung der Website und die
            Speicherung der Daten in Logfiles ist für den Betrieb der
            Internetseite zwingend erforderlich. Es besteht folglich seitens des
            Nutzers keine Widerspruchsmöglichkeit. <br /><br /><strong
              >IV. Kontaktformular und E-Mail-Kontakt </strong
            ><br />1. Beschreibung und Umfang der Datenverarbeitung <br />Auf
            unserer Internetseite ist ein Kontaktformular vorhanden, welches für
            die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein
            Nutzer diese Möglichkeit wahr, so werden die in der Eingabemaske
            eingegeben Daten an uns übermittelt und gespeichert. Diese Daten
            sind: <br />E-Mail-Adresse des Nutzers, Name des Nutzers, Betreff
            und Text der Nachricht<br />
            Zum Zeitpunkt der Absendung der Nachricht werden zudem folgende
            Daten gespeichert: <br />IP-Adresse des Nutzers, Datum und Uhrzeit
            der Absendung<br />
            Für die Verarbeitung der Daten wird im Rahmen des Absendevorgangs
            Ihre Einwilligung eingeholt und auf diese Datenschutzerklärung
            verwiesen. Alternativ ist eine Kontaktaufnahme über die
            bereitgestellte E-Mail-Adresse möglich. In diesem Fall werden die
            mit der E-Mail übermittelten personenbezogenen Daten des Nutzers
            gespeichert. Es erfolgt in diesem Zusammenhang keine Weitergabe der
            Daten an Dritte. Die Daten werden ausschließlich für die
            Verarbeitung der Konversation verwendet. <br /><br />2.
            Rechtsgrundlage für die Datenverarbeitung<br />
            Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen
            einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
            Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
            Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit.
            f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages
            ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6
            Abs. 1 lit. b DSGVO. <br /><br />3. Zweck der Datenverarbeitung<br />
            Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske
            dient uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer
            Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche
            berechtigte Interesse an der Verarbeitung der Daten. Die sonstigen
            während des Absendevorgangs verarbeiteten personenbezogenen Daten
            dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und
            die Sicherheit unserer informationstechnischen Systeme
            sicherzustellen. <br /><br />4. Dauer der Speicherung<br />
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
            ihrer Erhebung nicht mehr erforderlich sind. Für die
            personenbezogenen Daten aus der Eingabemaske des Kontaktformulars
            und diejenigen, die per E-Mail übersandt wurden, ist dies dann der
            Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist.
            Beendet ist die Konversation dann, wenn sich aus den Umständen
            entnehmen lässt, dass der betroffene Sachverhalt abschließend
            geklärt ist. Die während des Absendevorgangs zusätzlich erhobenen
            personenbezogenen Daten werden spätestens nach einer Frist von
            sieben Tagen gelöscht.<br /><br />
            5. Widerspruchs- und Beseitigungsmöglichkeit<br />
            Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur
            Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der
            Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung
            seiner personenbezogenen Daten jederzeit widersprechen. In einem
            solchen Fall kann die Konversation nicht fortgeführt werden. Der
            Nutzer hat jederzeit die Möglichkeit seine Einwilligung zu
            widerrufen als auch der Speicherung zu widersprechen, indem er
            dieses mit einfacher E-Mail an die Adresse
            kontakt@frauenaerztin-dier.de erklärt. Alle personenbezogenen Daten,
            die im Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem
            Fall gelöscht.<br /><br />
            <strong>V. Rechte der betroffenen Person</strong><br />
            Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
            Betroffener i. S. d. DSGVO und es stehen Ihnen folgende Rechte
            gegenüber dem Verantwortlichen zu: <br /><br />1. Auskunftsrecht<br />
            Sie können von dem Verantwortlichen eine Bestätigung darüber
            verlangen, ob personenbezogene Daten, die Sie betreffen, von uns
            verarbeitet werden. Liegt eine solche Verarbeitung vor, können Sie
            über folgende Informationen Auskunft verlangen: <br />(1) die
            Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
            <br />(2) die Kategorien von personenbezogenen Daten, welche
            verarbeitet werden; <br />(3) die Empfänger bzw. die Kategorien von
            Empfängern, gegenüber denen die Sie betreffenden personenbezogenen
            Daten offengelegt wurden oder noch offengelegt werden; <br />(4) die
            geplante Dauer der Speicherung der Sie betreffenden
            personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
            möglich sind, Kriterien für die Festlegung der Speicherdauer;
            <br />(5) das Bestehen eines Rechts auf Berichtigung oder Löschung
            der Sie betreffenden personenbezogenen Daten, eines Rechts auf
            Einschränkung der Verarbeitung durch den Verantwortlichen oder eines
            Widerspruchsrechts gegen diese Verarbeitung; <br />(6) das Bestehen
            eines Beschwerderechts bei einer Aufsichtsbehörde; <br />(7) alle
            verfügbaren Informationen über die Herkunft der Daten, wenn die
            personenbezogenen Daten nicht bei der betroffenen Person erhoben
            werden; <br />(8) das Bestehen einer automatisierten
            Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1
            und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige
            Informationen über die involvierte Logik sowie die Tragweite und die
            angestrebten Auswirkungen einer derartigen Verarbeitung für die
            betroffene Person. Ihnen steht das Recht zu, Auskunft darüber zu
            verlangen, ob die Sie betreffenden personenbezogenen Daten in ein
            Drittland oder an eine internationale Organisation übermittelt
            werden. In diesem Zusammenhang können Sie verlangen, über die
            geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der
            Übermittlung unterrichtet zu werden. <br /><br />2. Recht auf
            Berichtigung<br />
            Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
            gegenüber dem Verantwortlichen, sofern die verarbeiteten
            personenbezogenen Daten, die Sie betreffen, unrichtig oder
            unvollständig sind. Der Verantwortliche hat die Berichtigung
            unverzüglich vorzunehmen. <br /><br />3. Recht auf Einschränkung der
            Verarbeitung<br />
            Unter den folgenden Voraussetzungen können Sie die Einschränkung der
            Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
            <br />(1) wenn Sie die Richtigkeit der Sie betreffenden
            personenbezogenen für eine Dauer bestreiten, die es dem
            Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen
            Daten zu überprüfen; <br />(2) die Verarbeitung unrechtmäßig ist und
            Sie die Löschung der personenbezogenen Daten ablehnen und
            stattdessen die Einschränkung der Nutzung der personenbezogenen
            Daten verlangen; <br />(3) der Verantwortliche die personenbezogenen
            Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie
            diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen benötigen, oder <br />(4) wenn Sie Widerspruch
            gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben
            und noch nicht feststeht, ob die berechtigten Gründe des
            Verantwortlichen gegenüber Ihren Gründen überwiegen. Wurde die
            Verarbeitung der Sie betreffenden personenbezogenen Daten
            eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen
            – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
            anderen natürlichen oder juristischen Person oder aus Gründen eines
            wichtigen öffentlichen Interesses der Union oder eines
            Mitgliedstaats verarbeitet werden. Wurde die Einschränkung der
            Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie
            von dem Verantwortlichen unterrichtet bevor die Einschränkung
            aufgehoben wird.<br /><br />
            4. Recht auf Löschung<br />
            a) Löschungspflicht <br />Sie können von dem Verantwortlichen
            verlangen, dass die Sie betreffenden personenbezogenen Daten
            unverzüglich gelöscht werden, und der Verantwortliche ist
            verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der
            folgenden Gründe zutrifft: <br />(1) Die Sie betreffenden
            personenbezogenen Daten sind für die Zwecke, für die sie erhoben
            oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
            <br />(2) Sie widerrufen Ihre Einwilligung, auf die sich die
            Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a
            DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage
            für die Verarbeitung. <br />(3) Sie legen gem. Art. 21 Abs. 1 DSGVO
            Widerspruch gegen die Verarbeitung ein und es liegen keine
            vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie
            legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung
            ein. <br />(4) Die Sie betreffenden personenbezogenen Daten wurden
            unrechtmäßig verarbeitet. <br />(5) Die Löschung der Sie
            betreffenden personenbezogenen Daten ist zur Erfüllung einer
            rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
            Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
            <br />(6) Die Sie betreffenden personenbezogenen Daten wurden in
            Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art.
            8 Abs. 1 DSGVO erhoben. <br />b) Information an Dritte <br />Hat der
            Verantwortliche die Sie betreffenden personenbezogenen Daten
            öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren
            Löschung verpflichtet, so trifft er unter Berücksichtigung der
            verfügbaren Technologie und der Implementierungskosten angemessene
            Maßnahmen, auch technischer Art, um für die Datenverarbeitung
            Verantwortliche, die die personenbezogenen Daten verarbeiten,
            darüber zu informieren, dass Sie als betroffene Person von ihnen die
            Löschung aller Links zu diesen personenbezogenen Daten oder von
            Kopien oder Replikationen dieser personenbezogenen Daten verlangt
            haben. <br />c) Ausnahmen <br />Das Recht auf Löschung besteht
            nicht, soweit die Verarbeitung erforderlich ist <br />(1) zur
            Ausübung des Rechts auf freie Meinungsäußerung und Information;
            <br />(2) zur Erfüllung einer rechtlichen Verpflichtung, die die
            Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem
            der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung
            einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen
            wurde; <br />(3) aus Gründen des öffentlichen Interesses im Bereich
            der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie
            Art. 9 Abs. 3 DSGVO; <br />(4) für im öffentlichen Interesse
            liegende Archivzwecke, wissenschaftliche oder historische
            Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1
            DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich
            die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht
            oder ernsthaft beeinträchtigt, oder <br />(5) zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen.<br /><br />
            5. Recht auf Unterrichtung<br />
            Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung
            der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist
            dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
            personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
            Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen,
            es sei denn, dies erweist sich als unmöglich oder ist mit einem
            unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber dem
            Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu
            werden. <br /><br />6. Recht auf Datenübertragbarkeit<br />
            Sie haben das Recht, die Sie betreffenden personenbezogenen Daten,
            die Sie dem Verantwortlichen bereitgestellt haben, in einem
            strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
            Außerdem haben Sie das Recht, diese Daten einem anderen
            Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem
            die personenbezogenen Daten bereitgestellt wurden, zu übermitteln,
            sofern <br />(1) die Verarbeitung auf einer Einwilligung gem. Art. 6
            Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem
            Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und <br />(2) die
            Verarbeitung mithilfe automatisierter Verfahren erfolgt. <br />In
            Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass
            die Sie betreffenden personenbezogenen Daten direkt von einem
            Verantwortlichen einem anderen Verantwortlichen übermittelt werden,
            soweit dies technisch machbar ist. Freiheiten und Rechte anderer
            Personen dürfen hierdurch nicht beeinträchtigt werden. Das Recht auf
            Datenübertragbarkeit gilt nicht für eine Verarbeitung
            personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
            erforderlich ist, die im öffentlichen Interesse liegt oder in
            Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
            übertragen wurde. <br /><br />7. Widerspruchsrecht<br />
            Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
            Situation ergeben, jederzeit gegen die Verarbeitung der Sie
            betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
            lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch
            für ein auf diese Bestimmungen gestütztes Profiling. <br />Der
            Verantwortliche verarbeitet die Sie betreffenden personenbezogenen
            Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige
            Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
            und Freiheiten überwiegen, oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
            Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
            Widerspruch gegen die Verarbeitung der Sie betreffenden
            personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
            dies gilt auch für das Profiling, soweit es mit solcher
            Direktwerbung in Verbindung steht. Widersprechen Sie der
            Verarbeitung für Zwecke der Direktwerbung, so werden die Sie
            betreffenden personenbezogenen Daten nicht mehr für diese Zwecke
            verarbeitet. Sie haben die Möglichkeit, im Zusammenhang mit der
            Nutzung von Diensten der Informationsgesellschaft – ungeachtet der
            Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels
            automatisierter Verfahren auszuüben, bei denen technische
            Spezifikationen verwendet werden. <br /><br />8. Recht auf Widerruf
            der datenschutzrechtlichen Einwilligungserklärung<br />
            Sie haben das Recht, Ihre datenschutzrechtliche
            Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf
            der Einwilligung wird die Rechtmäßigkeit der aufgrund der
            Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
            <br /><br />9. Recht auf Beschwerde bei einer Aufsichtsbehörde<br />
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
            einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
            Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
            Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der
            Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
            <br />Die Aufsichtsbehörde, bei der die Beschwerde eingereicht
            wurde, unterrichtet den Beschwerdeführer über den Stand und die
            Ergebnisse der Beschwerde einschließlich der Möglichkeit eines
            gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},
  methods: {},

  data: () => ({
    impressum: false,
    datenschutz: false,
  }),
};
</script>
<style lang="scss">
$duration: 0.5s;
.transition {
  overflow: hidden;
}
// router view
.router-view-enter-active {
  transition: transform $duration ease-in-out;
  z-index: 2;
  transform: translateY(100%);
}
.router-view-enter-to {
  z-index: 2;
  transform: translateY(0%);
}
</style>

