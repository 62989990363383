import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/components/HomePage.vue'
import Aktuelles from '@/components/Aktuelles.vue'
import Werdegang from '@/components/Werdegang.vue'
import Leistungen from '@/components/Leistungen.vue'
import Anfahrt from '@/components/Anfahrt.vue'
import Corona from '@/components/Corona.vue'

Vue.use(VueRouter)
const routes=[
	{
			path:'/',
			name:'Home',
			component:HomePage,
	},
	{
			path:'/aktuelles',
			name:'Aktuelles',
			component:Aktuelles,
	},
	{
			path:'/werdegang',
			name:'Werdegang',
			component:Werdegang,
	},
	{
			path:'/leistungen',
			name:'Leistungen',
			component:Leistungen,
	},
	{
			path:'/anfahrt',
			name:'Anfahrt',
			component:Anfahrt,
	},
	{
		path:'/corona',
		name:'Corona',
		component:Corona,
},

]
const router = new VueRouter({
    routes
})

export default router