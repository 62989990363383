<template>
  <v-container fill-height fluid class="pa-0">
    <v-row
      justify="center"
      :class="$vuetify.breakpoint.smAndDown ? 'pb-16 mb-16' : ''"
    >
      <v-col cols="11" md="5" lg="5" xl="3">
        <v-card elevation="12" class="mt-16 mb-6" color="white">
          <v-card-title class="primary--text"
            ><v-icon color="primary" left>mdi-clock-outline</v-icon
            >Öffnungszeiten</v-card-title
          >
          <v-card-text>
            <v-btn-toggle
              dark
              v-model="openingHours"
              :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-8'"
              :dense="$vuetify.breakpoint.smAndDown"
            >
              <v-btn
                v-for="(i, index) in hours"
                :key="index"
                color="primary"
                :value="i.day"
              >
                {{ i.day }}
              </v-btn>
            </v-btn-toggle>
            <v-card flat>
              <v-card-text
                style="font-size: 16px"
                :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'mx-4 mt-4'"
                >{{ hours.find((item) => item.day == openingHours).times
                }}<span
                  v-if="hours.find((item) => item.day == openingHours).times2"
                  ><br />{{
                    hours.find((item) => item.day == openingHours).times2
                  }}</span
                ></v-card-text
              >
            </v-card>
          </v-card-text>
          <v-card-text style="font-size: 16px">
            <v-icon color="primary" left>mdi-phone-classic</v-icon>
            (07243) 5247969 oder (07243) 9616 (Dr. Ulrike Wirth)
          </v-card-text>
        </v-card>
        <v-card elevation="12" class="mt-4 mb-6" color="white">
          <v-card-title class="primary--text"
            ><v-icon color="primary" left>mdi-clock-outline</v-icon>Offene
            Sprechstunde</v-card-title
          >
          <v-card-text>
            <v-btn-toggle
              dark
              v-model="openingHours"
              :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-8'"
              :dense="$vuetify.breakpoint.smAndDown"
            >
              <v-btn
                v-for="(i, index) in hoursFree"
                :key="index"
                color="primary"
                :value="i.day"
              >
                {{ i.day }}
              </v-btn>
            </v-btn-toggle>
            <v-card flat>
              <v-card-text
                style="font-size: 16px"
                :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'mx-4 mt-4'"
                >{{
                  hoursFree.find((item) => item.day == openingHours).times
                }}</v-card-text
              >
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="11" md="6" lg="5" xl="5">
        <v-card
          elevation="12"
          color="white"
          :class="$vuetify.breakpoint.smAndDown ? 'pb-6' : 'my-16 pb-6'"
        >
          <v-card-title class="primary--text"
            ><v-icon color="primary" left>mdi-map-marker</v-icon
            >Anfahrt</v-card-title
          >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1956.6025690950578!2d8.371140034170077!3d48.924224516571975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4797051756a9ab81%3A0xfead2213c621a011!2sNeue%20Bruchstra%C3%9Fe%202A%2C%2076275%20Ettlingen!5e0!3m2!1sen!2sde!4v1640204776722!5m2!1sen!2sde"
            :width="cardWidth"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            :class="$vuetify.breakpoint.smAndDown ? 'mx-4' : 'mx-12'"
          ></iframe>
          <v-card-text
            style="font-size: 16px"
            :class="$vuetify.breakpoint.smAndDown ? 'mx-1' : 'mx-8'"
          >
            <span class="font-weight-bold primary--text"
              >Öffentlichen Verkehrsmittel:</span
            ><br />
            Buslinie 110 bis Haltestelle "Am Sang"<br />
            Buslinie 104 bis Haltestelle "Ettlingen Eisenstock"<br />
            Straßenbahn S81/S71 in Richtung Raststatt/Baden-Baden bis
            Haltestelle Ettlingen-Bruchhausen
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HomePage",
  computed: {
    ...mapState(["hours", "hoursFree"]),
    cardWidth() {
      let width = 0;
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          width = 660;
          break;
        case "lg":
          width = 500;
          break;
        case "md":
          width = 420;
          break;
        case "sm":
          width = 500;
          break;
        case "xs":
          width = 320;
          break;
      }
      return width;
    },
  },
  data() {
    return {
      openingHours: "Mo",
    };
  },
  methods: {},
  created() {
    const date = new Date();
    let day = date.getDay();
    switch (day) {
      case 0:
        this.openingHours = "So";
        break;
      case 1:
        this.openingHours = "Mo";
        break;
      case 2:
        this.openingHours = "Di";
        break;
      case 3:
        this.openingHours = "Mi";
        break;
      case 4:
        this.openingHours = "Do";
        break;
      case 5:
        this.openingHours = "Fr";
        break;
      case 6:
        this.openingHours = "Sa";
        break;
    }
  },
};
</script>