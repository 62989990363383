<template>
  <v-container fill-height fluid >
		<v-row justify="center" class="pb-16 mb-16">
			<v-col cols="12" md="8">
				<v-timeline
					align-top
					:dense="$vuetify.breakpoint.smAndDown"
				>
				<v-timeline-item
					v-for="i in werdegang"
					:key="i.year"
					color="primary"
				>
					<v-card
						color="primary"
						dark
						class="secondary-bg"
						elevation="12"
					>
						<v-card-title class="text-h6" style="word-break:normal;">
							{{i.year}} | {{i.title}}
						</v-card-title>
						<v-card-text class="white text--primary pt-4" v-if="i.description">
							{{i.description}}
						</v-card-text>
					</v-card>
				</v-timeline-item>
				</v-timeline>
			</v-col>
		</v-row>

  </v-container>
</template>

<script>
import {mapState}from 'vuex'
export default {
	computed:{
		...mapState(['werdegang'])

	},
	data(){
		return{
			
		}
	}
}
</script>
<style lang="scss">
$color-secondary:linear-gradient(45deg, #020024 20%, #090979 70%, #00d4ff 100%);
.secondary-bg{
  background-image: $color-secondary !important;
}
</style>