<template>
  <v-container fill-height fluid class="pa-0">
    <v-row class="justify-center mb-16 pb-16">
      <v-col cols="11" sm="10" md="10" lg="11" xl="8">
        <v-card class="mt-12" elevation="12">
          <v-card flat dark class="secondary-bg">
            <v-card-title style="word-break: normal"
              >Informationen zu Corona Impfungen</v-card-title
            >
          </v-card>
          <v-card-text
            style="font-size: 16px"
            :class="$vuetify.breakpoint.smAndDown ? 'pa-6' : 'pa-12'"
          >
            Ich impfe mit mRNA Impfstoffen (bioNTech). Falls Sie sich bei uns
            gegen Corona impfen lassen möchten, bitten wir Sie, sich online auf
            dem
            <a
              href="https://www.impftermin-bw.de/"
              target="_blank"
              style="text-decoration: none"
              class="font-weight-bold primary--text"
              >Impfterminportal</a
            >
            zu registrieren. Dort sind meine freien Termine ersichtlich. Bitte
            denken Sie auch daran, folgende Dokumente vorab herunter zu laden
            und zu Ihrem Termin ausgefüllt mitzubringen:
          </v-card-text>
          <v-card-actions
            :class="
              $vuetify.breakpoint.mdAndDown ? 'pb-4 flex-column' : 'pb-10'
            "
          >
            <v-btn
              color="primary"
              dark
              elevation="0"
              href="./Einwilligung-de.pdf"
              target="_blank"
              :class="$vuetify.breakpoint.mdAndDown ? 'mx-2' : 'ml-8 mr-2'"
              :block="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon left>mdi-file-document-multiple</v-icon>Einwilligung
            </v-btn>
            <v-btn
              color="primary"
              dark
              elevation="0"
              href="./Aufklaerungsbogen-de.pdf"
              target="_blank"
              :class="$vuetify.breakpoint.mdAndDown ? 'mt-2 mx-2' : 'mx-2'"
              :block="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon left>mdi-file-document-multiple</v-icon>Aufklärungsbogen
            </v-btn>
            <v-btn
              color="primary"
              dark
              elevation="0"
              href="./Aufklaerungsbogen-Englisch.pdf"
              target="_blank"
              :class="$vuetify.breakpoint.mdAndDown ? 'mt-2 mx-2' : 'mx-2'"
              :block="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon left>mdi-file-document-multiple</v-icon>Information Sheet
            </v-btn>
            <v-btn
              color="primary"
              dark
              elevation="0"
              href="./Aufklaerungsbogen-Franzoesisch.pdf"
              target="_blank"
              :class="$vuetify.breakpoint.mdAndDown ? 'mt-2 mx-2' : 'mx-2'"
              :block="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon left>mdi-file-document-multiple</v-icon>Fiche
              d'information
            </v-btn>
            <v-btn
              color="primary"
              dark
              elevation="0"
              href="./Aufklaerungsbogen-Tuerkisch.pdf"
              target="_blank"
              :class="$vuetify.breakpoint.mdAndDown ? 'mt-2 mx-2' : 'mx-2'"
              :block="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon left>mdi-file-document-multiple</v-icon>Bilgilendirme
              sayfası
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Corona",
  computed: {},
  data() {
    return {};
  },
};
</script>