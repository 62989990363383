import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/* eslint-disable no-unused-vars */
export default new Vuex.Store({
  state: {
    werdegang:[
      {
        year: '1977',
        title: 'Abitur',
        description:''
      },
      {
        year: '1978-1981',
        title: 'Krankenschwestern-Ausbildung',
        description:''
      },
      {
        year: '1981-1982',
        title: 'Architekturstudium',
        description:''
      },
      {
        year: '1983-1988',
        title: 'Medizinstudium',
        description:'In Gent, Münster und Freiburg.'
      },
      {
        year: '1988-1989',
        title: 'Praktisches Jahr',
        description:'In Karlsruhe im St. Vincentius Krankenhaus in den Abteilungen Gynäkologie, Innere Medizin und Chirurgie'
      },
      {
        year: '1989-1992',
        title: 'Assistenzärztin',
        description:'Kinderchirurgie in Karlsruhe'
      },
      {
        year: '1992-1993',
        title: 'Assistenzärztin',
        description:'Gynäkologie im Krankenhaus Kandel'
      },
      {
        year: '1993 -1994',
        title: 'Assistenzärztin',
        description:'In allgemeinmedizinischer Praxis'
      },
      {
        year: '1994-1995',
        title: 'Babypause',
        description:''
      },
      {
        year: '1995-1999',
        title: 'Facharztausbildung Gynäkologie',
        description:'In Bruchsal'
      },
      {
        year: '1999 - 31.12.2021',
        title: 'Gynäkologische Gemeinschaftspraxis',
        description:'In Ettlingen'
      },
      {
        year: 'seit 2012',
        title: 'Humanitärer Einsatz',
        description:'regelmäßiger humanitärer medizinischer Einsatz in Haiti'
      },
      {
        year: 'seit 2018',
        title: 'Humanitärer Einsatz',
        description:'regelmäßiger humanitärer medizinischer Einsatz in Togo'
      },
      {
        year: 'seit 01.01.2022',
        title: 'Praxisgemeinschaft',
        description:'in Ettlingen-Bruchhausen'
      }
    
    ],
    leistungen:[
      {
        name:'Gynäkologische Vorsorge & Früherkennungsuntersuchungen',
        description:{
          text:'IGEL Leistungen:',
          leistungen:['Ultraschalluntersuchungen', 'Spiraleneinlage', 'Dünnschichtzytologie (Thin-Prep-Pap)', 'Darmkrebsvorsorge vor dem 50. Lebensjahr',  'Chlamydien-Test nach dem 25. Lebensjahr']
        },
      },
      {
        name:'Beratung über Verhütungsmethoden',

      },
      {
        name:'Erstberatung junger Mädchen / Kinder- und Jugendgynäkologie',

      },
      {
        name:'Hormondiagnostik und –therapie',

      },
      {
        name:'Betreuung in den Wechseljahren, Altersgynäkologie & Osteoporoseberatung',

      },
      {
        name:'Allgemeine Krebsvorsorge & Nachsorge',

      },
      {
        name:'Diagnostik, Therapie und Nachsorge bösartiger gynäkologischer Erkrankungen',

      },
      {
        name:'Betreuung bei Familienplanung & Kinderwunsch',

      },
      {
        name:'Schwangerschaftsbetreuung',
        description:{
          text:'Hierzu zählen:',
          leistungen:['Die Vorsorge und Nachsorge gemäß der Mutterschaftsrichtlinien', 'Diagnostik und Therapie schwangerschaftsspezifischer Erkrankungen', 'Betreuung von Risikoschwangerschaften', 'Doppler',  'Praena Test ab der 11. Woche sind seit dem 01.07.2022 bei Risiko Schwangerschaften Kassenleistung']
        },
      },
      {
        name:'sämtliche Impfungen (inkl. Covid-19, auch für Partner und andere Angehörige)',

      },
    ],
    hours:[
      {
        day: 'Mo',
        times:'12:30 - 18:30 Uhr'
      },
      {
        day: 'Di',
        times:'07:30 - 13:30 Uhr'
      },
      {
        day: 'Mi',
        times:'Nach Vereinbarung'
      },
      {
        day: 'Do',
        times:'07:00 - 14:00 Uhr'
      },
      {
        day: 'Fr',
        times:'12:30 - 18:30 Uhr',
        times2:'14:00 - 18:30 Uhr Impftermine'
      },
      {
        day: 'Sa',
        times:'Nach Vereinbarung'
      },
      {
        day: 'So',
        times:'Geschlossen'
      },
    ],
    hoursFree:[
      {
        day: 'Mo',
        times:'13:00 - 14:00 Uhr'
      },
      {
        day: 'Di',
        times:'08:00 - 09:30 Uhr'
      },
      {
        day: 'Mi',
        times:'keine'
      },
      {
        day: 'Do',
        times:'08:00 - 09:30 Uhr'
      },
      {
        day: 'Fr',
        times:'13:00 - 14:00 Uhr'
      },
      {
        day: 'Sa',
        times:'keine'
      },
      {
        day: 'So',
        times:'Geschlossen'
      },
    ],
    aktuelles:[
      {
        title:'Impfung mit neuem Impfstoff von Biontec',
        intro:'Ab Oktober bieten wir Impfungen <strong>Freitag Nachmittags ab 14 Uhr</strong> an. Die Impfungen erfolgen mit dem neuen Impfstoff von BioNTec.',
        outtro:'Um einen Termin hierfür asuszumachen, rufen Sie uns bitte an, da das Impfportal aktuell nicht mehr betrieben wird. Die neue Einverständniserklärung finden Sie online auf der Homepage des RKI. Vielen Dank und wir freuen uns auf Sie!',
      },
      {
        title:'Wichtige Informationen zum Coronavirus',
        intro:'Des Schutzes unserer Patientinnen und unseres medizinischen Personales wegen müssen wir Sie bitten, folgende Maßnahmen unbedingt einzuhalten:',
        bulletpoints:['Alle Personen, die Kontakt mit einer an CoVid 19 erkrankten Person haben oder hatten, bitten wir, die Praxis <strong>nicht</strong> zu betreten.',
                      'Sollten Sie Fieber oder Atemwegsbeschwerden leiden, oder ein konkreter Verdacht auf eine Coronainfektion vorliegen, bitten wir Sie, Ihren Termin <strong>abzusagen</strong> und sich umgehend an das zuständige Gesundheitsamt zu wenden.',
                      'Bringen Sie zu Ihrem Termin bitte maximal eine Begleitperson mit',
                      'Die Praxisräumlichkeiten dürfen - laut Gesetzgeber - <strong>nur</strong> mit FFP2-Maske betreten werden.'
                    ],
        outtro:'Wir bitten um Ihr Verständnis und danken Ihnen für Ihre Mithilfe!',
      }
    ]
  },
  mutations: {

  }
})
/* eslint-enable no-unused-vars */