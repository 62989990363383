<template>
  <v-container fill-height fluid class="pa-0">
    <v-row class="justify-center pb-16">
      <v-col cols="11" md="10" lg="8">
        <v-card class="mt-12 mb-16" elevation="12" v-for="(i, index) in aktuelles" :key="index">
          <v-card flat dark class="secondary-bg">
          <v-card-title  style="word-break:normal;">{{i.title}}</v-card-title>
          </v-card>
          <v-card-text style="font-size:16px;" :class="$vuetify.breakpoint.smAndDown? 'pa-6':'pa-12'">
            <span v-html="i.intro"></span> <br>
            <ul v-if="i.bulletpoints" class="mt-6">
              <li v-for="(i, index) in i.bulletpoints" :key="index" v-html="i"></li>
            </ul> <br>
            <span v-if="i.outtro" v-html="i.outtro"></span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import {mapState}from 'vuex'
  export default {
    name: 'Aktuelles',
    computed:{
		...mapState(['aktuelles'])

	},
    data(){
      return{

      }
    }
  }
</script>
