<template>
  <v-container fill-height fluid class="pa-0">
    <v-row justify="space-around" align="center"  :class="$vuetify.breakpoint.mdAndDown? 'py-16':''">
      <v-col cols="11" lg="4" xl="4" :class="$vuetify.breakpoint.mdAndDown? 'mb-16':''">

        <v-card flat color="transparent" class="mb-10">
          <div class="d-flex flex-no-wrap justify-center">
            <v-avatar :class="$vuetify.breakpoint.smAndDown? 'my-auto mr-8 primary-border':'my-auto mx-16 primary-border'" :size="$vuetify.breakpoint.smAndDown? '130':'200'" align-self="bottom">
              <v-img src="@/assets/annette-avatar.png"/>
            </v-avatar>
            <div :class="$vuetify.breakpoint.smAndDown? 'floaty-box py-6 px-10':'floaty-box py-10 px-16'" >
              <h1 :class="$vuetify.breakpoint.smAndDown? 'primary-text display-1':'primary-text display-2'">WILL<br>KOM<br>MEN</h1>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="11" lg="6" xl="6">
        <v-card elevation="12" color="white" class="mx-4 pa-12">
          <v-card-text style="font-size:18px;" class="primary--text font-weight-light">
            Das Praxisteam und ich bieten das gesamte fachliche Spektrum einer modernen gynäkologischen Praxis. 
            Dies erreichen wir nicht zuletzt durch unsere Ausstattung, sowie unsere freundliche Atmosphäre, sondern auch durch das ausgezeichnete Personal.<br>
            Wir beraten und behandeln jede Patientin ganz individuell und basieren unsere Methodiken auf langjähriger Erfahrung, einem umfassenden Leistungsspektrum 
            und nicht zuletzt auch einem Schatz an fachbereichübergreifendem Wissen.
            Ich würde mich sehr freuen, Sie bald bei uns begrüßen zu dürfen!

          </v-card-text>
          <v-spacer/>
          <v-card-text style="font-size:18px;" class="primary--text font-weight-light">            
            Sind Sie <span class="font-weight-bold">Neupatientin</span>? Dann können Sie sich hier die folgenden Formulare bereits bei Ihnen zuhause ausdrucken und zu Ihrem Termin mitbringen!
          </v-card-text>
          <v-card-actions class="justify-center mt-16">
            <v-btn color="primary" elevation="0" href="./anamnese.pdf" target="_blank" class="mx-auto">
              <v-icon left>mdi-file-document-multiple</v-icon>Anamnese<span v-if="$vuetify.breakpoint.mdAndUp">bogen</span> 	&#38; Datenschutz<span v-if="$vuetify.breakpoint.mdAndUp">erklärung</span>
            </v-btn>
          </v-card-actions>

        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'HomePage',
    data(){
      return{

      }
    }
  }
</script>
<style lang="scss">
$color-box-shadow:rgba(34, 50, 92,.7);
$color-primary:#020024;
$color-box:rgb(255, 255, 255);
$color-secondary:linear-gradient(90deg, #020024 0%, #090979 35%, #00d4ff 100%);
.secondary-bg{
  background-image: $color-secondary !important;
}
.primary-text{
  color: $color-box;
}
.floaty-box {
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 15px;
  //background-color: $color-primary;
  background-image:linear-gradient(45deg, #020024 0%, #090979 35%, #00d4ff 100%);
  border-radius: 11px;
  position: relative;
  box-shadow: 20px 20px $color-box-shadow;
  // border: 1px solid $color-green;
}
.floaty-box:after {
  transform: translatey(0px);
  animation: float2 5s ease-in-out infinite;
  content: ".";
  font-weight: bold;
  // -webkit-text-stroke: 0.5px $color-green;
  -webkit-text-fill-color: $color-primary;
  // border: 1px solid $color-green;
  text-shadow: 22px 22px $color-box-shadow;
  text-align: left;
  font-size: 55px;
  width: 55px;
  height: 11px;
  line-height: 30px;
  border-radius: 11px;
  position: absolute;
  display: block;
  bottom: -30px;
  left: 0;
  box-shadow: 22px 22px $color-box-shadow;
  z-index: -2;
  //background-color: $color-primary;
  background-image:linear-gradient(45deg, #020024 50%, #090979,);

}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float2 {
  0% {
    line-height: 30px;
    transform: translatey(0px);
  }
  55% {
    transform: translatey(-20px);
  }
  60% {
    line-height: 10px;
  }
  100% {
    line-height: 30px;
    transform: translatey(0px);
  }
}

.gyn-bg{
  background-image: url("../assets/bg-wide-transparent.png");
  min-height: 400px;
  min-width: 100%;
  background-repeat: repeat-y;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
.primary-border{
  border: 3px solid $color-primary;
}
</style>
