<template>
  <v-container fill-height fluid>
		<v-row justify="center" align="center" class="pb-16 mb-16 mt-12">
			<v-col cols="12" xl="8" lg="8" md="10">
				<v-card elevation="12"  class="mb-2" v-for="(item) in leistungen"
					:key="item.name">
					<div v-if="!item.description">
					<v-card-title style="font-size:18px; word-break:normal">{{item.name}}</v-card-title>
					</div>
					<v-expansion-panels v-else>
						<v-expansion-panel>
							<v-expansion-panel-header class="py-0 pl-0 pr-6">
								<v-card-title style="font-size:18px; word-break:normal">{{item.name}}</v-card-title>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-card-text v-if="item.description" class="pt-2">
									{{item.description.text}}
									<ul v-if="item.description.leistungen">
										<li v-for="(i, index) in item.description.leistungen" :key="index">{{i}}</li>
									</ul>
								</v-card-text>
							</v-expansion-panel-content>
							</v-expansion-panel>
					</v-expansion-panels>

				</v-card>
			</v-col>
		</v-row>

  </v-container>
</template>

<script>
	import {mapState}from 'vuex'
  export default {
		computed:{
		...mapState(['leistungen'])

	},
    data(){
      return{

      }
    }
  }
</script>
<style lang="scss">
$color-secondary:#e1eaf5;
.secondary-bg{
  background-color: $color-secondary !important;
}
</style>
